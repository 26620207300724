































































































































































































































































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import {
  STATE_PROGRAM_REQUEST,
  SLICE_UPDATE,
  SLICE_DELETE,
  SLICE_CREATE,
  SLICE_REQUEST_DISTRICT,
} from "@/store/actions/stateProgram";
import { Region } from "@/models/Region";
import {
  REGION_REQUEST,
  REGION_UPDATED,
  REGION_LOADED,
} from "@/store/actions/main";

@Component({})
export default class RegionalPassportForm extends Vue {
  private form: any = {};
  private slices: any = [];
  private sliceToAdd: any = {};
  private regions: any = [];
  private districts: any = [];
  private loading: boolean = false;
  private menu = false;

  //Snackbar
  private messageText = "Не удалось выполнить";
  private messageColor = "red";
  private message = false;
  //Initialise methods
  private initIfReady() {
    //return true if started to init self.
    let needReload = [] as boolean[];
    needReload.push(
      this.needReload(
        this.$store.getters.REGION_TABLE,
        this.$store.getters.REGION_LOADED
      )
    );
    for (let i = 0; i < needReload.length; i++) {
      if (needReload[i] == true) {
        return false;
      }
    }
    console.log("TRUE INIT SELF");
    this.initSelf();
    return true;
  }
  private needReload(table, updated) {
    /*
		data is a table loading from request.
		updated is flag that is false when table has old data and need to be reloading.
		return true if need reload.
		*/
    if (table == null || !updated) return true;
    return false;
  }
  private tryToInitSelf() {
    this.loading = true;
    //const data = this.getParams();
    if (
      this.needReload(
        this.$store.getters.REGION_TABLE,
        this.$store.getters.REGION_LOADED
      )
    ) {
      this.$store.dispatch(REGION_REQUEST).then(() => {
        this.initIfReady();
      });
    }
    if (this.initIfReady()) return;
  }
  private initSelf() {
    console.log("INIT SELF upd");
    //this.regions = this.$store.getters.REGION_TABLE.data;
    if (this.isUpdatePage()) {
      this.initStartOptions();
    }
    this.sortSlices();
    this.loading = false;
    console.log("loading");
  }
  //Methods
  private isRegionChosen() {
    if (this.regions) {
      if (this.regions.length > 0) return true;
    }
    return false;
  }
  private regionSelect() {
    console.log("regionSelect", this.form.region_id);
    let id = this.form.region_id;
    for (let i = 0; i < this.regions.length; i++) {
      if (id == this.regions[i].id) this.districts = this.regions[i].districts;
    }
    this.slices = [] as any;
    this.form.district_id = 0;
  }
  private optionSelect() {
    if (this.form.region_id > 0 && this.form.district_id > 0) {
      console.log("optionSelect init");
      let csp = this.toString(localStorage.getItem("currentStateProgram"));
      let parsed = JSON.parse(csp);
      let csp_id = parsed.id;
      let region_id = this.form.region_id;
      this.form.state_program_id = +this.$route.params.id;
      console.log("district id", this.form.district_id);
      let data = {
        params: {
          program_id: +this.$route.params.id,
          district_id: +this.form.district_id,
        },
      };
      this.$store.dispatch(SLICE_REQUEST_DISTRICT, data).then(() => {
        this.slices = this.$store.getters.SLICES_BY_REGION.data;
        console.log("slices", this.slices);
        this.sortSlices();
        //this.initSelf();
      });
    }
  }
  private getRegion(id) {
    for (let i = 0; i < this.regions.length; i++) {
      if (id == this.regions[i].id) return this.regions[i].title;
    }
  }
  private updateItem() {
    for (let i = 0; i < this.slices.length; i++) {
      this.updateSlice(i);
    }
    this.sortSlices();
  }
  private updateSlice(n) {
    var converted = new FormData();
    let sendForm = {} as any;
    sendForm.region_id = +this.form.region_id;
    sendForm.district_id = +this.form.district_id;
    sendForm.state_program_id = +this.form.state_program_id;
    sendForm.year = +this.slices[n].year;
    sendForm.appropriations_federal = +this.slices[n].appropriations_federal;
    sendForm.appropriations_local = +this.slices[n].appropriations_local;
    sendForm.appropriations_total = +this.slices[n].appropriations_total;
    sendForm.cash_exec_federal = +this.slices[n].cash_exec_federal;
    sendForm.cash_exec_local = +this.slices[n].cash_exec_local;
    sendForm.cash_exec_total = +this.slices[n].cash_exec_total;
    sendForm.cash_exec_share_federal = +this.slices[n].cash_exec_share_federal;
    sendForm.cash_exec_share_local = +this.slices[n].cash_exec_share_local;
    sendForm.cash_exec_share_total = +this.slices[n].cash_exec_share_total;
    // Object.keys(this.slices[n]).forEach((key, val) => {
    //   sendForm[key] = val;
    // });
    Object.keys(sendForm).forEach((key) => {
      console.log(key);
      converted.append(key, sendForm[key]);
    });
    // converted.delete("id");
    // converted.delete("state_program_id");
    // converted.delete("region_id");
    // converted.delete("district_id");
    // converted.delete("district");
    var data = {
      id: +this.slices[n].id,
      data: converted,
    };
    console.log("form", this.form);
    console.log("sendform", sendForm);
    this.$store
      .dispatch(SLICE_UPDATE, data)
      .then(() => {
        this.messageText = "Срез изменен.";
        this.messageColor = "green";
      })
      .catch(() => {
        this.messageText = "Не удалось выполнить";
        this.messageColor = "red";
      })
      .finally(() => {
        this.message = true;
      });
  }
  private createItem() {
    var converted = new FormData();
    converted.append("region_id", this.form.region_id);
    converted.append("district_id", this.form.district_id);
    //converted.append("state_program_id", this.form.state_program_id);
    console.log("sta", this.sliceToAdd);
    console.log("region_id", this.form.region_id);
    console.log("district_id", this.form.district_id);

    converted.append("year", this.sliceToAdd.year);
    converted.append(
      "appropriations_federal",
      this.sliceToAdd.appropriations_federal
    );
    converted.append(
      "appropriations_local",
      this.sliceToAdd.appropriations_local
    );
    converted.append(
      "appropriations_total",
      this.sliceToAdd.appropriations_total
    );
    converted.append("cash_exec_federal", this.sliceToAdd.cash_exec_federal);
    converted.append("cash_exec_local", this.sliceToAdd.cash_exec_local);
    converted.append("cash_exec_total", this.sliceToAdd.cash_exec_total);
    converted.append(
      "cash_exec_share_federal",
      this.sliceToAdd.cash_exec_share_federal
    );
    converted.append(
      "cash_exec_share_local",
      this.sliceToAdd.cash_exec_share_local
    );
    converted.append(
      "cash_exec_share_total",
      this.sliceToAdd.cash_exec_share_total
    );
    // Object.keys(this.sliceToAdd).forEach((key) => {
    //   console.log("key",key);
    //   let d = +this.sliceToAdd[key];
    //   console.log("val",d);
    //   converted.append(key, d);
    // });
    converted.delete("id");
    var data = {
      data: converted,
      params: {
        program_id: +this.form.state_program_id,
      },
    };
    //console.log("form", this.form);
    this.$store
      .dispatch(SLICE_CREATE, data)
      .then(() => {
        this.messageText = "Срез добавлен.";
        this.messageColor = "green";
        // this.slices.push({
        //   id: +this.sliceToAdd.id,
        //   state_program_id: +this.form.state_program_id,
        //   district_id: +this.form.district_id,
        //   region_id: +this.form.region_id,
        //   year: +this.sliceToAdd.year,
        //   appropriations_federal: +this.sliceToAdd.appropriations_federal,
        //   appropriations_local: +this.sliceToAdd.appropriations_local,
        //   appropriations_total: +this.sliceToAdd.appropriations_total,
        //   cash_exec_federal: +this.sliceToAdd.cash_exec_federal,
        //   cash_exec_local: +this.sliceToAdd.cash_exec_local,
        //   cash_exec_total: +this.sliceToAdd.cash_exec_total,
        //   cash_exec_share_federal: +this.sliceToAdd.cash_exec_share_federal,
        //   cash_exec_share_local: +this.sliceToAdd.cash_exec_share_local,
        //   cash_exec_share_total: +this.sliceToAdd.cash_exec_share_total,
        // })
        this.optionSelect();
      })
      .catch(() => {
        this.messageText = "Не удалось выполнить";
        this.messageColor = "red";
      })
      .finally(() => {
        this.message = true;
      });
  }
  private deleteItem(item) {
    this.$store
      .dispatch(SLICE_DELETE, item.id)
      .then(() => {
        this.messageText = "Срез удален.";
        this.messageColor = "green";
        this.optionSelect();
      })
      .catch(() => {
        this.messageText = "Не удалось выполнить";
        this.messageColor = "red";
      })
      .finally(() => {
        this.message = true;
      });
  }
  private sortSlices() {
    this.slices.sort(function (a, b) {
      return a.year - b.year;
    });
  }
  private toString(str) {
    return str as string;
  }
  private isUpdatePage() {
    return this.$route.meta.isUpdate;
  }
  private initStartOptions() {
    var item;
    //console.log("district_id", item.district_id);
    //this.form.district_id = item.district_id;
    this.regionSelect();
    this.$forceUpdate();
    console.log("form: ", this.form);
  }
  private preInitSelf() {
    this.$store.dispatch(REGION_REQUEST).then(() => {
      this.regions = this.$store.getters.REGION_TABLE.data;
      let item = {} as any;
      if (this.isUpdatePage()) {
        if (localStorage.getItem("slice")) {
          item = JSON.parse(this.toString(localStorage.getItem("slice")));
          this.$router.currentRoute.meta.breadcrumb[4].text =
            "Срез по области " +
            this.getDistrict(item.region_id, item.district_id);
          this.form.region_id = item.region_id;
          this.regionSelect();
          this.form.district_id = item.district_id;
          this.optionSelect();
          console.log("pre Init upd", this.form);
          //this.$router.currentRoute.meta.breadcrumb[2].href = "/#/pages/indicators/regionalPassports/"+item.indicator_id;
        }
      }
      if (localStorage.getItem("stateProgramTitle")) {
        let title = localStorage.getItem("stateProgramTitle");
        this.$router.currentRoute.meta.breadcrumb[2].text = title;
      }
      let data = {
        params: {
          program_id: item.state_program_id,
          district_id: item.district_id,
        },
      };
      this.$store.dispatch(SLICE_REQUEST_DISTRICT, data).then(() => {
        this.slices = this.$store.getters.SLICES_BY_REGION.data;
        console.log("slices", this.slices);
        this.sortSlices();
        //this.initSelf();
      });
    });
  }
  private getDistrict(region_id, id) {
    let region = {} as any;
    reg_search: for (let i = 0; i < this.regions.length; i++) {
      if (region_id == this.regions[i].id) {
        region = this.regions[i];
        break reg_search;
      }
    }
    for (let i = 0; i < region.districts.length; i++) {
      if (id == region.districts[i].id) return region.districts[i].title;
    }
  }
  private getDistrictName(region_id, id) {
    let region = {} as any;
    reg_search: for (let i = 0; i < this.regions.length; i++) {
      if (region_id == this.regions[i].id) {
        region = this.regions[i];
        break reg_search;
      }
    }
    for (let i = 0; i < region.districts.length; i++) {
      if (id == region.districts[i].id) return region.districts[i].name;
    }
  }
  //Hooks
  private created() {
    this.preInitSelf();
  }
  private mounted() {}
  private destroyed() {
    //localStorage.removeItem("regionalPassport");
  }
}
